const getUri = data => {
  if (!data) return "/"
  const regex = /https/i
  const urlSite1 = process.env.GATSBY_PAGINA_COMPILADOR
  if (!urlSite1) return
  const urlSite2 = urlSite1.replace(regex, "http")
  const newText = data.replace(urlSite1, "").replace(urlSite2, "")
  const textoSinPrimeraBarra = newText.slice(1)
  return process.env.GATSBY_PAGINA_OFICIAL + textoSinPrimeraBarra
}

export default getUri

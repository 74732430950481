import React from "react"
import { css } from "@emotion/react"
import Title from "../../atoms/Title/Title"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Image from "../../atoms/Image/Image"

export default function EstarDetalles({ data }) {
  const sectionDetallesCss = css`
  `

  const sectionDetallesContentCss = css`
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `

  const sectionDetallesLeftCss = css`
    width: calc(100vw * (calc(809 / var(--width_base))));
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;

    @media (max-width: 767px) {
      width: 100%;
    }
  `

  const titleCss = css`
    font-size: calc(100vw * (calc(50 / var(--width_base))));
    font-family: "Poppins Light", sans-serif;
    max-width: calc(100vw * (calc(414 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(57 / var(--width_base))));

    & > strong{
      font-family: "Poppins Bold", sans-serif;
    }

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(30 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
    }
  `

  const textCss = css`
    font-family: "Poppins Light", sans-serif;
    font-weight: 300;
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    max-width: calc(100vw * (calc(900 / var(--width_base))));

    & > p{
      margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    }

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(15 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
      max-width: initial;
      // margin-bottom: calc(100vw * (calc(70 / var(--width_base))));
    }
  `

  const imageCss = css`
    
    &,&.gatsby-image-wrapper img{
      object-fit: cover;
      height: calc(100vw * (calc(850 / var(--width_base))));
      @media (max-width: 767px) {
        height: calc(100vw * (calc(500 / var(--width_base))));
      }
    }

  `

  const sectionDetallesRightCss = css`
    padding: calc(100vw * (calc(132 / var(--width_base))))
      calc(100vw * (calc(245 / var(--width_base))));
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px) {
      padding: calc(100vw * (calc(80 / var(--width_base))))
        calc(100vw * (calc(40 / var(--width_base))));
    }
  `

  const imageSecundariaCss = css`
    background-color: #ededed;
    width: 100%;
    &.gatsby-image-wrapper img{
      object-fit: contain !important;
      width: calc(100vw * (calc(900 / var(--width_base))));
      height: calc(100vw * (calc(700 / var(--width_base))));
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 767px) {
        width: calc(100vw * (calc(250 / var(--width_base))));
        height: calc(100vw * (calc(230 / var(--width_base))));
      }
    }

  `

  return (
    <section css={sectionDetallesCss}>
      <div css={sectionDetallesContentCss}>
        <div css={sectionDetallesLeftCss}>
          {data?.detallesImagen && (
            <Image
              classEle="w-full"
              objectFit="cover"
              styleCss={imageCss}
              imagen={data?.detallesImagen}
            />
          )}
        </div>
        <div className="w-full" css={sectionDetallesRightCss}>
          {data?.detallesTitulo && (
            <Title
              styleCss={titleCss}
              text={data?.detallesTitulo}
              typeEle="h2"
            />
          )}
          {data?.detallesTexto && (
            <Paragraph styleCss={textCss} text={data?.detallesTexto} />
          )}
        </div>
      </div>
      {
        data?.detallesImagenSecundaria && (
          <Image
              classEle="w-full"
              objectFit="cover"
              styleCss={imageSecundariaCss}
              imagen={data?.detallesImagenSecundaria}
            />
        )
      }
    </section>
  )
}

import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import LayoutSinNavegacion from "../components/organisms/Layout/LayoutSinNavegacion"
import getUri from "../helper/getUri"
import EstarBanner from "../components/templates/EstarCerca/EstarBanner"
import EstarFooter from "../components/templates/EstarCerca/EstarFooter"
import EstarTengamos from "../components/templates/EstarCerca/EstarTengamos"
import EstarDonde from "../components/templates/EstarCerca/EstarDonde"
import EstarHola from "../components/templates/EstarCerca/EstarHola"
import EstarDetalles from "../components/templates/EstarCerca/EstarDetalles"
import EstarGaleria from "../components/templates/EstarCerca/EstarGaleria"
import EstarPlanos from "../components/templates/EstarCerca/EstarPlanos"

export const query = graphql`
  query ($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_EstamosCerca {
            templateName
            estarcerca {
              bannerSubtitulo
              bannerTitulo
              botonTexto
              botonPdf {
                mediaItemUrl
              }
              detallesImagen {
                ...imagen
              }
              detallesImagenSecundaria {
                ...imagen
              }
              detallesTexto
              detallesTitulo
              dondeIdEvolta
              dondeProyectos {
                direccion
                distrito
                titulo
                imagen {
                  ...imagen
                }
              }
              dondePaginaDeGracias {
                ... on Wordpress_Page {
                  uri
                }
              }
              dondeTextoBoton
              dondeTitulo
              dondeUrlBoton
              footerCelular
              footerTelefono
              footerTextoLink
              galeriaImagenes {
                ...imagen
              }
              galeriaTitulo
              holaImagenPortada {
                ...imagen
              }
              holaTexto
              holaTitulo
              planoTitulo
              planosCards {
                area
                dormitorio
                imagen {
                  ...imagen
                }
              }
              tengamosTitulo
            }
          }
        }
      }
    }
  }
`

export default function EstarCerca({ data }) {
  const page = data?.wordpress?.page
  const template = page?.template?.estarcerca

  return (
    <LayoutSinNavegacion classEle="">
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">Mucho Gusto</h1>
      <EstarBanner data={template} />
      <EstarDetalles data={template} />
      <EstarHola data={template} />
      <EstarPlanos data={template} />
      <EstarGaleria data={template} />
      <EstarTengamos data={template} />
      <EstarDonde data={template} />
      <EstarFooter data={template} />
    </LayoutSinNavegacion>
  )
}

import React from "react"
import { graphql } from "gatsby"
import LayoutGeneral from "../components/organisms/Layout/LayoutGeneral"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { Helmet } from "react-helmet"
import GraciasProyecto from "../components/templates/Proyecto/GraciasProyecto"
import ConoceInicio from "../components/templates/Inicio/ConoceInicio"
import getUri from "../helper/getUri"
export const query = graphql`
  query ($id: ID!) {
    wordpress {
      currentPage: page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        template {
          ... on Wordpress_Template_Gracias {
            gracias {
              colorLineas
              imagen {
                ...imagen
              }
              pdfBrochure {
                mediaItemUrl
              }
              pdfPlanos {
                mediaItemUrl
              }
              subtitulo
              texto
              titulo
            }
          }
        }
      }
      specificPage: page(idType: DATABASE_ID, id: "912") {
        template {
          ... on Wordpress_Template_Inicio {
            inicio {
              ...conoceInicio
            }
          }
        }
      }
    }
  }
`

export default function Gracias({ data }) {
  const page = data.wordpress.currentPage
  const template = page?.template?.gracias
  const pageInicio = data.wordpress.specificPage
  const templateInicio = pageInicio?.template?.inicio
  return (
    <LayoutGeneral>
      <Helmet>
        <link rel="canonical" href={getUri(page.seo.canonical)} />
      </Helmet>
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      <GraciasProyecto
        tituloProyecto={page.title}
        imagen={template?.imagen}
        texto={template?.texto}
        subtitulo={template?.subtitulo}
        titulo={template?.titulo}
        pdfBrochure={template?.pdfBrochure?.mediaItemUrl}
        pdfPlanos={template?.pdfPlanos?.mediaItemUrl}
        colorLineas={template?.colorLineas}
      />
      <ConoceInicio
        titulo={templateInicio?.tituloConoce}
        proyectos={templateInicio?.proyectosConoce}
      />
    </LayoutGeneral>
  )
}

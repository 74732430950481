import React from "react"
import { css } from "@emotion/react"
import AccordionProyectoItem from "./AccordionProyectoItem"

export default function AccordionProyectos({ data }) {
  const sanIsidro = data && data.filter(item => item?.distrito === "san-isidro")
  const miraflores =
    data && data.filter(item => item?.distrito === "miraflores")
  const barranco = data && data.filter(item => item?.distrito === "barranco")
  const surco = data && data.filter(item => item?.distrito === "surco")

  const accordionCss = css`
    border-top: 1px solid black;
  `

  return (
    <div css={accordionCss}>
      {sanIsidro.length > 0 && (
        <AccordionProyectoItem
          data={{ items: sanIsidro, title: "San Isidro" }}
        />
      )}
      {miraflores.length > 0 && (
        <AccordionProyectoItem
          data={{ items: miraflores, title: "Miraflores" }}
        />
      )}
      {barranco.length > 0 && (
        <AccordionProyectoItem data={{ items: barranco, title: "Barranco" }} />
      )}
      {surco.length > 0 && (
        <AccordionProyectoItem data={{ items: surco, title: "Surco" }} />
      )}
    </div>
  )
}

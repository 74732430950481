import React from "react"
import { css } from "@emotion/react"

export default function EstarBanner({ data }) {
  const sectionArmandoCss = css`
    background-color: #232222;
    font-size: calc(100vw * (calc(120 / var(--width_base))));
    line-height: calc(100vw * (calc(120 / var(--width_base))));
    font-family: "Poppins SemiBold", sans-serif;
    text-align: center;
    color: white;
    height: calc(100vw * (calc(888 / var(--width_base))));
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(32 / var(--width_base))));
      line-height: calc(100vw * (calc(32 / var(--width_base))));
      height: calc(100vw * (calc(304 / var(--width_base))));
      padding-left: calc(100vw * (calc(30 / var(--width_base))));
      padding-right: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const textTitleCss = css`
    max-width: calc(100vw * (calc(1500 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(70 / var(--width_base))));
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(20 / var(--width_base))));
    }
  `
  const textSubtitleCss = css`
    max-width: calc(100vw * (calc(1500 / var(--width_base))));
    font-size: calc(100vw * (calc(40 / var(--width_base))));
    line-height: calc(100vw * (calc(30 / var(--width_base))));
    font-family: "Poppins Light", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(20 / var(--width_base))));
    }
  `

  return (
    <section css={sectionArmandoCss}>
      {data?.bannerTitulo && <p css={textTitleCss}>{data?.bannerTitulo}</p>}
      {data?.bannerSubtitulo && (
        <p css={textSubtitleCss}>{data?.bannerSubtitulo}</p>
      )}
    </section>
  )
}

import React from "react"
import { css } from "@emotion/react"
import { Link } from "gatsby"

export default function EstarFooter({ data }) {
  const sectionFooterCss = css`
    background-color: #e6e6e6;
    text-align: center;
    padding-top: calc(100vw * (calc(81 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(61 / var(--width_base))));
    font-size: calc(100vw * (calc(20 / var(--width_base))));
    line-height: calc(100vw * (calc(25 / var(--width_base))));
    font-family: "Poppins Light", sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(12 / var(--width_base))));
      line-height: calc(100vw * (calc(18 / var(--width_base))));
      padding-top: calc(100vw * (calc(24 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(13 / var(--width_base))));
    }
  `

  return (
    <section css={sectionFooterCss}>
      {data?.footerTextoLink && (
        <Link
          to="/"
          css={css`
            color: black;
          `}
        >
          {data?.footerTextoLink}
        </Link>
      )}
      <p>---</p>
      {data?.footerTelefono && <p>t. {data?.footerTelefono}</p>}
      {data?.footerCelular && <p>c. {data?.footerCelular}</p>}
    </section>
  )
}

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buena-senal-js": () => import("./../../../src/pages/buena-senal.js" /* webpackChunkName: "component---src-pages-buena-senal-js" */),
  "component---src-pages-gracias-libro-reclamaciones-js": () => import("./../../../src/pages/gracias-libro-reclamaciones.js" /* webpackChunkName: "component---src-pages-gracias-libro-reclamaciones-js" */),
  "component---src-pages-pdf-js": () => import("./../../../src/pages/pdf.js" /* webpackChunkName: "component---src-pages-pdf-js" */),
  "component---src-templates-armando-js": () => import("./../../../src/templates/Armando.js" /* webpackChunkName: "component---src-templates-armando-js" */),
  "component---src-templates-como-imaginas-js": () => import("./../../../src/templates/ComoImaginas.js" /* webpackChunkName: "component---src-templates-como-imaginas-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/Default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-depas-js": () => import("./../../../src/templates/Depas.js" /* webpackChunkName: "component---src-templates-depas-js" */),
  "component---src-templates-dueno-de-un-armando-js": () => import("./../../../src/templates/DuenoDeUnArmando.js" /* webpackChunkName: "component---src-templates-dueno-de-un-armando-js" */),
  "component---src-templates-entregados-js": () => import("./../../../src/templates/Entregados.js" /* webpackChunkName: "component---src-templates-entregados-js" */),
  "component---src-templates-estar-cerca-js": () => import("./../../../src/templates/EstarCerca.js" /* webpackChunkName: "component---src-templates-estar-cerca-js" */),
  "component---src-templates-gracias-js": () => import("./../../../src/templates/Gracias.js" /* webpackChunkName: "component---src-templates-gracias-js" */),
  "component---src-templates-inicio-js": () => import("./../../../src/templates/Inicio.js" /* webpackChunkName: "component---src-templates-inicio-js" */),
  "component---src-templates-libro-reclamaciones-js": () => import("./../../../src/templates/LibroReclamaciones.js" /* webpackChunkName: "component---src-templates-libro-reclamaciones-js" */),
  "component---src-templates-mucho-gusto-js": () => import("./../../../src/templates/MuchoGusto.js" /* webpackChunkName: "component---src-templates-mucho-gusto-js" */),
  "component---src-templates-piloto-nogales-js": () => import("./../../../src/templates/PilotoNogales.js" /* webpackChunkName: "component---src-templates-piloto-nogales-js" */),
  "component---src-templates-politicas-privacidad-js": () => import("./../../../src/templates/PoliticasPrivacidad.js" /* webpackChunkName: "component---src-templates-politicas-privacidad-js" */),
  "component---src-templates-programa-referidos-js": () => import("./../../../src/templates/ProgramaReferidos.js" /* webpackChunkName: "component---src-templates-programa-referidos-js" */),
  "component---src-templates-protocolo-js": () => import("./../../../src/templates/Protocolo.js" /* webpackChunkName: "component---src-templates-protocolo-js" */),
  "component---src-templates-proyecto-entregado-js": () => import("./../../../src/templates/ProyectoEntregado.js" /* webpackChunkName: "component---src-templates-proyecto-entregado-js" */),
  "component---src-templates-proyecto-gracias-js": () => import("./../../../src/templates/ProyectoGracias.js" /* webpackChunkName: "component---src-templates-proyecto-gracias-js" */),
  "component---src-templates-proyecto-js": () => import("./../../../src/templates/Proyecto.js" /* webpackChunkName: "component---src-templates-proyecto-js" */),
  "component---src-templates-proyecto-proximamente-js": () => import("./../../../src/templates/ProyectoProximamente.js" /* webpackChunkName: "component---src-templates-proyecto-proximamente-js" */),
  "component---src-templates-proyectos-js": () => import("./../../../src/templates/Proyectos.js" /* webpackChunkName: "component---src-templates-proyectos-js" */),
  "component---src-templates-terminos-condiciones-js": () => import("./../../../src/templates/TerminosCondiciones.js" /* webpackChunkName: "component---src-templates-terminos-condiciones-js" */)
}


import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import fileSaver from "file-saver"

export default function Button({
  link,
  type,
  nombre,
  color,
  styleType,
  children,
  styleEle,
  eventEle,
  typePdf = null,
}) {
  const saveFile = (url, nombre = "pdf") => {
    console.log({ typePdf })
    if (typePdf) {
      if (typePdf === "brochure")
        window.dataLayer.push({ event: "descargar_brochure" })
      if (typePdf === "plano")
        window.dataLayer.push({ event: "descargar_plano" })
    }

    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        fileSaver.saveAs(blob, nombre)
      })
  }

  let styleButtonCss = {}

  const alinearCss = css`
    display: inline-flex;
    align-items: center;
    align-content: center;
  `

  switch (styleType) {
    case "estarcerca":
      styleButtonCss = css`
        color: white;
        background-color: #096654;
        font-family: "Poppins Light", sans-serif;
        padding: calc(100vw * (calc(10 / var(--width_base))))
          calc(100vw * (calc(40 / var(--width_base))));
        font-size: calc(100vw * (calc(20 / var(--width_base))));
        line-height: calc(100vw * (calc(30 / var(--width_base))));
        font-weight: 600;
        letter-spacing: calc(100vw * (calc(2 / var(--width_base))));
        min-width: calc(100vw * (calc(300 / var(--width_base))));
        justify-content: center;
        align-items: center;
        gap: calc(100vw * (calc(10 / var(--width_base))));
        border-radius: calc(100vw * (calc(50 / var(--width_base))));
        display: inline-flex;
        @media (max-width: 767px) {
          gap: calc(100vw * (calc(5 / var(--width_base))));
          font-size: calc(100vw * (calc(16 / var(--width_base))));
          min-width: calc(100vw * (calc(210 / var(--width_base))));
          padding: calc(100vw * (calc(4 / var(--width_base))))
            calc(100vw * (calc(15 / var(--width_base))));
        }
      `
      break
    case "outline":
      styleButtonCss = css`
        color: ${color};
        border: calc(100vw * (calc(10 / var(--width_base)))) solid ${color};
        font-family: "Source Sans Pro", sans-serif;
        padding: calc(100vw * (calc(20 / var(--width_base))))
          calc(100vw * (calc(20 / var(--width_base))));
        font-size: calc(100vw * (calc(30 / var(--width_base))));
        line-height: calc(100vw * (calc(30 / var(--width_base))));
        font-weight: 600;
        letter-spacing: calc(100vw * (calc(2 / var(--width_base))));
        min-width: calc(100vw * (calc(300 / var(--width_base))));
        text-transform: uppercase;
        justify-content: center;
        @media (max-width: 767px) {
          font-size: calc(100vw * (calc(18 / var(--width_base))));
          min-width: calc(100vw * (calc(210 / var(--width_base))));
          padding: calc(100vw * (calc(15 / var(--width_base))))
            calc(100vw * (calc(35 / var(--width_base))));
        }
        ${styleEle};
        ${alinearCss};
      `
      break

    case "outline-small":
      styleButtonCss = css`
        color: ${color};
        border: calc(100vw * (calc(6 / var(--width_base)))) solid ${color};
        font-family: "Source Sans Pro", sans-serif;
        padding: calc(100vw * (calc(13 / var(--width_base))))
          calc(100vw * (calc(20 / var(--width_base))));
        font-size: calc(100vw * (calc(20 / var(--width_base))));
        line-height: calc(100vw * (calc(20 / var(--width_base))));
        font-weight: 600;
        letter-spacing: calc(100vw * (calc(2 / var(--width_base))));
        text-transform: uppercase;
        @media (max-width: 767px) {
          width: 100%;
          letter-spacing: 0.05em;
        }
        ${styleEle};
        ${alinearCss};
      `
      break
    case "normal":
      styleButtonCss = css`
        &:not(:last-of-type) {
          margin-right: calc(100vw * (calc(25 / var(--width_base))));
          @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: calc(100vw * (calc(25 / var(--width_base))));
          }
        }
        background-color: ${color};
        padding: calc(100vw * (calc(18 / var(--width_base))))
          calc(100vw * (calc(20 / var(--width_base))));
        font-size: calc(100vw * (calc(18 / var(--width_base))));
        font-family: "Source Sans Pro", sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        color: white;
        & .btn__icon {
          margin-right: calc(100vw * (calc(10 / var(--width_base))));
        }
        ${styleEle};
        ${alinearCss};
        @media (max-width: 767px) {
          width: 100%;
          justify-content: center;
        }
      `
      break
    default:
      styleButtonCss = css`
        color: ${color};
        border: calc(100vw * (calc(10 / var(--width_base)))) solid ${color};
        font-family: "Source Sans Pro", sans-serif;
        padding: calc(100vw * (calc(20 / var(--width_base))))
          calc(100vw * (calc(20 / var(--width_base))));
        font-size: calc(100vw * (calc(30 / var(--width_base))));
        line-height: calc(100vw * (calc(30 / var(--width_base))));
        font-weight: 600;
        letter-spacing: calc(100vw * (calc(2 / var(--width_base))));
        min-width: calc(100vw * (calc(300 / var(--width_base))));
        ${styleEle};
        ${alinearCss};
      `
      break
  }
  return (
    <>
      {type === "pdf" && (
        // <a href={`/pdf/?url=${base64_encode(link)}`} target="_blank" rel="noreferrer" css={styleButtonCss}>{children}</a>
        <button onClick={() => saveFile(link, nombre)} css={styleButtonCss}>
          {children}
        </button>
      )}
      {type === "link" && link && (
        <Link to={link} css={styleButtonCss}>
          {children}
        </Link>
      )}
      {type === "whatsapp" && link && (
        <a href={link} target="_blank" rel="noreferrer" css={styleButtonCss}>
          {children}
        </a>
      )}
      {type === "button" && (
        <button css={styleButtonCss} type="button" onClick={eventEle}>
          {children}
        </button>
      )}
    </>
  )
}

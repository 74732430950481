import React, { useState } from "react"
import Carousel from "../../organisms/Carousel/Carousel"
import Image from "../../atoms/Image/Image"
import { css } from "@emotion/react"
import Title from "../../atoms/Title/Title"
import Paragraph from "../../atoms/Paragraph/Paragraph"

export default function EstarSliderProyectos({ data }) {
  const [stepNumber, setStepNumber] = useState(0)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false,
    beforeChange: (current, next) => setStepNumber(next),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          dots: false,
        },
      },
    ],
  }

  const cardCss = css`
    padding: 0 calc(100vw * (calc(8 / var(--width_base))));
  `

  const imageCss = css`
    height: calc(100vw * (calc(555 / var(--width_base))));
  `

  const overlayCss = css`
    position: absolute;
    inset: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: calc(100vw * (calc(40 / var(--width_base))))
      calc(100vw * (calc(36 / var(--width_base))));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `

  const titleCss = css`
    font-family: "Poppins SemiBold", sans-serif;
    font-size: calc(100vw * (calc(30 / var(--width_base))));
    line-height: calc(100vw * (calc(40 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(20 / var(--width_base))));

    &::after {
      content: "";
      display: block;
      width: calc(100vw * (calc(19 / var(--width_base))));
      height: calc(100vw * (calc(7 / var(--width_base))));
      background-color: white;
      margin-top: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const textCss = css`
    font-family: "Poppins Regular", sans-serif;
    font-size: calc(100vw * (calc(20 / var(--width_base))));
  `

  const sectionsCss = css`
    & .slick-list {
      padding-right: calc(100vw * (calc(370 / var(--width_base))));
    }
  `

  const progressCss = css`
    & {
      -webkit-appearance: none;
      background-color: ;
      width: calc(100vw * (calc(882 / var(--width_base))));
      margin-left: calc(100vw * (calc(8 / var(--width_base))));
      margin-top: calc(100vw * (calc(70 / var(--width_base))));
    }
    &::-webkit-progress-bar {
      background-color: #d1d1d1;
      height: calc(100vw * (calc(6 / var(--width_base))));
    }

    &::-webkit-progress-value {
      background-color: #5a5a5a;
    }
  `

  return (
    <div css={sectionsCss}>
      <Carousel settings={settings}>
        {data &&
          data.map((item, index) => (
            <div key={index} css={cardCss}>
              <div className="relative">
                {item?.imagen && (
                  <Image styleCss={imageCss} imagen={item?.imagen} />
                )}
                <div css={overlayCss}>
                  {item?.titulo && (
                    <Title styleCss={titleCss} text={item?.titulo} />
                  )}
                  {item?.direccion && (
                    <Paragraph styleCss={textCss} text={item?.direccion} />
                  )}
                </div>
              </div>
            </div>
          ))}
      </Carousel>
      {data && (
        <progress
          css={progressCss}
          max={data.length}
          value={stepNumber}
        ></progress>
      )}
    </div>
  )
}

import React from "react"
import { css } from "@emotion/react"
import Image from "../../atoms/Image/Image"
import Button from "../../atoms/Button/Button"
import Icon from "../../atoms/Icon/Icon"

export default function EstarPlanos({ data }) {
  const titleCss = css`
    font-size: calc(100vw * (calc(50 / var(--width_base))));
    max-width: calc(100vw * (calc(500 / var(--width_base))));
    font-family: "Poppins Regular", sans-serif;
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
    padding-left: calc(100vw * (calc(13 / var(--width_base))));

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(33 / var(--width_base))));
      max-width: calc(100vw * (calc(260 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(47 / var(--width_base))));
    }
  `

  const contenedor = css`
    background-color: #ededed;
    padding-top: calc(100vw * (calc(160 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(160 / var(--width_base))));
    @media (max-width: 767px) {
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
      padding-top: calc(100vw * (calc(90 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(90 / var(--width_base))));
    }
  `

  const imagePlanoImagen = css`
    &,
    &.gatsby-image-wrapper img {
      object-fit: contain !important;
      width: 100%;
      // width: calc(100vw * (calc(500 / var(--width_base))));
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));

    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
    }
  `

  const textPlanoCss = css`
    font-size: calc(100vw * (calc(28 / var(--width_base))));
    font-family: "Poppins Light", sans-serif;
    text-align: center;
    padding-top: calc(100vw * (calc(20 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(20 / var(--width_base))));
    width: 100%;
    border-bottom: 1px solid black;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(20 / var(--width_base))));
      width: calc(100vw * (calc(200 / var(--width_base))));
      padding-top: calc(100vw * (calc(10 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(10 / var(--width_base))));
    }
  `

  const galeriaCss = css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(100vw * (calc(40 / var(--width_base))));
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: calc(100vw * (calc(20 / var(--width_base))));
    }
  `

  const contenedorContentCss = css`
    max-width: calc(100vw * (calc(1480 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
  `

  const galeriaItemCss = css`
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(100vw * (calc(60 / var(--width_base))));
    // flex-direction: column;
    padding: calc(100vw * (calc(60 / var(--width_base))));
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      padding: calc(100vw * (calc(35 / var(--width_base))));
      gap: calc(100vw * (calc(0 / var(--width_base))));
    }
  `

  const galeriaItemTextCss = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `

  const groupBtnsCss = css`
    text-align: center;
    margin-top: calc(100vw * (calc(40 / var(--width_base))));
  `

  const iconCss = css`
    width: calc(100vw * (calc(22 / var(--width_base))));
    height: calc(100vw * (calc(22 / var(--width_base))));

    @media (max-width: 767px) {
      width: calc(100vw * (calc(17 / var(--width_base))));
      height: calc(100vw * (calc(17 / var(--width_base))));
    }
  `

  const titlePlanoCss = css`
    font-size: calc(100vw * (calc(50 / var(--width_base))));
    font-family: "Poppins Regular", sans-serif;
    margin-bottom: calc(100vw * (calc(40 / var(--width_base))));
    padding-left: calc(100vw * (calc(13 / var(--width_base))));

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(33 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(47 / var(--width_base))));
    }

    & br {
      @media (max-width: 767px) {
        display: none;
      }
    }
  `

  return (
    <section css={contenedor}>
      <div css={contenedorContentCss}>
        {data?.titulo && <div css={titleCss}>{data?.titulo}</div>}

        {data?.planoTitulo && (
          <h3
            css={titlePlanoCss}
            dangerouslySetInnerHTML={{ __html: data?.planoTitulo }}
          />
        )}

        <div css={galeriaCss}>
          {data?.planosCards?.map((plano, index) => (
            <div css={galeriaItemCss} key={index}>
              <div css={galeriaItemTextCss}>
                <h4 css={textPlanoCss}>{plano?.area}</h4>
                <h4 css={textPlanoCss}>{plano?.dormitorio}</h4>
              </div>
              {plano?.imagen && (
                <Image
                  imagen={plano?.imagen}
                  styleCss={imagePlanoImagen}
                  alt={plano?.imagen?.altText}
                />
              )}
            </div>
          ))}
        </div>

        <div class="text-center" css={groupBtnsCss}>
          {data?.botonTexto && (
            <Button
              link={data?.botonPdf?.mediaItemUrl}
              type="pdf"
              nombre={`Planos`}
              styleType="estarcerca"
            >
              <Icon type="planos2" styleEle={iconCss} />
              {data?.botonTexto}
            </Button>
          )}
        </div>
      </div>
    </section>
  )
}

import React from "react";
import Image from "../../atoms/Image/Image";
import {css} from "@emotion/react";

export default function EstarGaleria ({data}){
    
    const galeriaCss = css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: calc(100vw * (calc(40 / var(--width_base))));
        @media (max-width: 767px) {
            grid-template-columns: 1fr;
            gap: calc(100vw * (calc(10 / var(--width_base))));
        }
    `;

    const titleCss = css`
    font-size: calc(100vw * (calc(50 / var(--width_base))));
    font-family: "Poppins Regular", sans-serif;
    margin-bottom: calc(100vw * (calc(70 / var(--width_base))));

    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(33 / var(--width_base))));
      margin-bottom: calc(100vw * (calc(47 / var(--width_base))));

    }
  `

   const contenedorCss = css`
        max-width: calc(100vw * (calc(1480 / var(--width_base))));
        margin-left: auto;
        margin-right: auto;
        padding-top: calc(100vw * (calc(160 / var(--width_base))));
        padding-bottom: calc(100vw * (calc(160 / var(--width_base))));

        @media (max-width: 767px) {
            max-width: initial;
            padding-left: calc(100vw * (calc(20 / var(--width_base))));
            padding-right: calc(100vw * (calc(20 / var(--width_base))));
            padding-top: calc(100vw * (calc(90 / var(--width_base))));
            padding-bottom: calc(100vw * (calc(90 / var(--width_base))));
        }
    `;

    const imageCss = css`
        &,&.gatsby-image-wrapper img{
            height: calc(100vw * (calc(380 / var(--width_base))));
            object-fit: cover !important;
            width: 100%;

            @media (max-width: 767px) {
                height: calc(100vw * (calc(300 / var(--width_base))));
            }
        }
    `;

    return(
        <section css={contenedorCss}>
            {
                data?.galeriaTitulo && (
                    <h2 css={titleCss}>{data?.galeriaTitulo}</h2>
                )
            }
            <div css={galeriaCss}>
                {
                    data?.galeriaImagenes && data?.galeriaImagenes.map((imagen, index) => (
                        <div key={index}>
                            <Image imagen={imagen} styleCss={imageCss}/>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}